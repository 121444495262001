/*
This file contains the style for WRDS Classroom oldoldolds.

.navbar-wrds: styles for both logged in and logged out styles.
.navbar-logged-in: additional styles for logged in users.
.navbar-logged-out: additional styles for the publicly viewable site.

media-breakpoint-up(md): additional styles for larger view screens.
*/
.navbar-wrds {
    border-color: $wharton-blue;
    background-color: $wharton-blue;
    .navbar-brand {
        position: relative;
        width: 70%;
        img {
            width: 100%;
        }
    }
    .navbar-nav > li a.nav-link {
        font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
        font-weight: 400;
        letter-spacing: 1px;
        color: $white;
        &:hover, &:focus {
            outline: 0;
            color: $bay_blue;
        }
    }
    .navbar-nav > .active > a {
        border-radius: 0;
        color: $white;
        background-color: $bay_blue;
        &:hover, &:focus {
            color: $white;
            background-color: $bay_blue;
        }
    }
    .navbar-toggler {
        border-color: $white;
        color: $white;
        padding: 7px 3px 0px 3px;
    }
}

.nav-utilities-black-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 30px;
    background: #000;
    width: 100%;
    vertical-align: top;
    z-index: 9999;
}

.nav-utilities {
    .nav-link {
        font-size: 11px !important;
        color: $white;
    }
    .nav-item {
        color: $white;
        &:hover {
            color: $white;
            background: $wharton-red;
        }
        .dropdown-menu {
            left: auto;
            right: 0;
            top: 30px;
            background-color: $black;
        }
        .dropdown-item {
            color: $white;
            padding: 5px 10px;
            &:hover {
                color: $white;
                background: $wharton-red;
            }
        }
    }
}

/* For medium (md) breadpoints and higher only */
@include media-breakpoint-up(md) {
    /* Effect to shrink nav on scroll down page. */
    .navbar-logged-out {
        padding: 30px 0;
        background-color: rgba($wharton-blue, 0.3);
        &.navbar-shrink {
            height: 60px;
            padding: 10px 0;
            background-color: $wharton-blue;
        }
    }
    .navbar-logged-in {
        height: 60px;
        top: 30px;
    }
}
