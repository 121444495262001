section {
	padding: 50px 0;
	&#contact {
		background-color: $color_log_cabin_approx;
		background-position: center;
		background-repeat: no-repeat;
		.section-heading, .section-subheading, label {
			color: $white;
		}
	}
	h2.section-heading {
		text-align: center;
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 40px;
	}
	h3.section-subheading {
		margin-bottom: 20px;
		font-family: $font_0, $font_1, $font_2, $font_3;
		font-size: 16px;
		font-weight: 400;
	}
}

.main-body {
  padding-bottom: 80px;
}