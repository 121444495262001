//colors
$color_bay_of_many_30_approx: rgba(21, 66, 129, 0.3);
$color_bay_of_many_approx: rgba(21, 66, 129, 1);
$white: #fff;
$color_tapa_approx: #777;
$color_dodger_blue_approx: #06aafc;
$color_black_haze_approx: #f7f7f7;
$color_log_cabin_approx: #222;
$color_amber_approx: #f6bf01;
$white_100: rgba(255, 255, 255, 1);
$black: #000;
$color_cerulean_approx: #06aced;
$color_valencia_approx: #dd4b39;
$color_azure_approx: #3b5998;

//fonts
$font_0: Helvetica Neue;
$font_1: Helvetica;
$font_2: Arial;
$font_3: sans-serif;
$font_4: Montserrat;
$font_5: cursive;
$font_6: FontAwesome;

//urls
$url_0: url(/static/wrds/img/header-bg.jpg);

//@extend-elements
//original selectors
//.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary
%extend_1 {
	border-color: $color_amber_approx;
	color: $white;
	background-color: $color_dodger_blue_approx;
}


body {
	//overflow-x: hidden;
	//font-family: $font_0, $font_1, $font_2, $font_3;
	webkit-tap-highlight-color: $color_dodger_blue_approx;
}
.text-muted {
	color: $color_tapa_approx;
}
.text-primary {
	color: $color_dodger_blue_approx;
}
p {
	font-size: 14px;
	line-height: 1.75;
	&.large {
		font-size: 16px;
	}
}
a {
	outline: 0;
	color: $color_dodger_blue_approx;
	&:hover {
		outline: 0;
		color: $color_dodger_blue_approx;
	}
	&:focus {
		outline: 0;
		color: $color_dodger_blue_approx;
	}
	&:active {
		outline: 0;
		color: $color_dodger_blue_approx;
	}
	&.active {
		outline: 0;
		color: $color_dodger_blue_approx;
	}
}
h1 {
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-weight: 700;
}
h2 {
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-weight: 700;
}
h3 {
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-weight: 700;
}
h4 {
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-weight: 700;
}
h5 {
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-weight: 700;
}
h6 {
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-weight: 700;
}
.img-centered {
	margin: 0 auto;
}
.bg-light-gray {
	background-color: $color_black_haze_approx;
}
.bg-darkest-gray {
	background-color: $color_log_cabin_approx;
}
.btn-primary {
	border-color: $color_dodger_blue_approx;
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-weight: 700;
	color: $white;
	background-color: $color_dodger_blue_approx;
	&:hover {
		@extend %extend_1;
	}
	&:focus {
		@extend %extend_1;
	}
	&:active {
		@extend %extend_1;
		background-image: none;
	}
	&.active {
		@extend %extend_1;
		background-image: none;
	}
	&.disabled {
		border-color: $color_dodger_blue_approx;
		background-color: $color_dodger_blue_approx;
		&:hover, &:focus, &:active, &.active {
			border-color: $color_dodger_blue_approx;
			background-color: $color_dodger_blue_approx;
		}
	}
	.badge {
		color: $color_dodger_blue_approx;
		background-color: $white;
	}
}
.btn-primary[disabled] {
	border-color: $color_dodger_blue_approx;
	background-color: $color_dodger_blue_approx;
	&:hover, &:focus, &:active, &.active {
		border-color: $color_dodger_blue_approx;
		background-color: $color_dodger_blue_approx;
	}
}
fieldset[disabled] {
	.btn-primary {
		border-color: $color_dodger_blue_approx;
		background-color: $color_dodger_blue_approx;
		&:hover, &:focus, &:active, &.active {
			border-color: $color_dodger_blue_approx;
			background-color: $color_dodger_blue_approx;
		}
	}
	.btn-xl {
		border-color: $color_dodger_blue_approx;
		background-color: $color_dodger_blue_approx;
		&:hover, &:focus, &:active, &.active {
			border-color: $color_dodger_blue_approx;
			background-color: $color_dodger_blue_approx;
		}
	}
}
.btn-xl {
	padding: 20px 40px;
	border-color: $color_dodger_blue_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 3px;
	font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
	font-size: 18px;
	font-weight: 700;
	color: $white;
	background-color: $color_dodger_blue_approx;
	&:hover {
		@extend %extend_1;
	}
	&:focus {
		@extend %extend_1;
	}
	&:active {
		@extend %extend_1;
		background-image: none;
	}
	&.active {
		@extend %extend_1;
		background-image: none;
	}
	&.disabled {
		border-color: $color_dodger_blue_approx;
		background-color: $color_dodger_blue_approx;
		&:hover, &:focus, &:active, &.active {
			border-color: $color_dodger_blue_approx;
			background-color: $color_dodger_blue_approx;
		}
	}
	.badge {
		color: $color_dodger_blue_approx;
		background-color: $white;
	}
}
.btn-xl[disabled] {
	border-color: $color_dodger_blue_approx;
	background-color: $color_dodger_blue_approx;
	&:hover, &:focus, &:active, &.active {
		border-color: $color_dodger_blue_approx;
		background-color: $color_dodger_blue_approx;
	}
}


header {
	text-align: center;
	color: $white;
	background-attachment: scroll;
	background-image: $url_0;
	background-position: center center;
	background-repeat: none;
	background-size: cover;
	.intro-text {
		padding-top: 50px;
		padding-bottom: 50px;
		.intro-lead-in {
			margin-bottom: 25px;
			font-family: $font_0, $font_1, $font_2, $font_3;
			font-size: 14px;
			line-height: 14px;
			//Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			text-shadow: -1px 0 $color_tapa_approx, 0 1px $color_tapa_approx, 1px 0 $color_tapa_approx, 0 -1px $color_tapa_approx;
		}
		.intro-heading {
			margin-bottom: 25px;
			font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
			font-size: 50px;
			font-weight: 700;
			line-height: 50px;
			//Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			text-shadow: -1px 0 $color_tapa_approx, 0 1px $color_tapa_approx, 1px 0 $color_tapa_approx, 0 -1px $color_tapa_approx;
		}
	}
}

.service-heading {
	margin: 15px 0;
	text-transform: none;
}
@media(min-width:768px) {
	section {
		padding: 100px 0;
	}
	header .intro-text {
		padding-top: 300px;
		padding-bottom: 200px;
		.intro-lead-in {
			margin-bottom: 35px;
			font-family: $font_0, $font_1, $font_2, $font_3;
			font-size: 40px;
			line-height: 45px;
			background-color: rgba(0, 0, 0, 0.25);
		}
		.intro-heading {
			margin-bottom: 50px;
			font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
			font-size: 75px;
			font-weight: 700;
			line-height: 75px;
		}
	}
}

.btn {
	&:focus, &:active, &.active {
		outline: 0;
		&:focus {
			outline: 0;
		}
	}
}
::-moz-selection, ::selection {
	//Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	text-shadow: none;
	background: $color_dodger_blue_approx;
}
img::selection {
	background: 0 0;
}
img::-moz-selection {
	background: 0 0;
}
#login-dp {
	min-width: 250px;
	padding: 14px 14px 0;
	overflow: hidden;
	border: 1px solid $white;
  left: auto;
  right: 0;
	.help-block {
		font-size: 12px;
	}
	.form-group {
		margin-bottom: 10px;
	}
}
@media(max-width:768px) {
	#login-dp {
		background-color: inherit;
		color: $white;
	}
}
.socialaccount_provider {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	margin: 0.1rem;
	color: $white;
	border: 2px solid $white;
	&:hover {
		border: 2px solid $black;
		color: $white;
		text-decoration: none;
	}
	&:before {
		margin-right: 5px;
	}
}
.twitter {
	background-color: $color_cerulean_approx;
	&:before {
		content: "\f081 ";
		font-family: $font_6;
		color: $white;
	}
}
.google {
	background-color: $color_valencia_approx;
	&:before {
		content: "\f1a0 ";
		font-family: $font_6;
		color: $white;
	}
}
.facebook {
	background-color: $color_azure_approx;
	&:before {
		content: "\f082 ";
		font-family: $font_6;
		color: $white;
	}
}
.open .dropdown-toggle {
	&.btn-primary, &.btn-xl {
		@extend %extend_1;
		background-image: none;
	}
}
