// TYPOGRAPHY
// -------------------------
$font-family:						"Helvetica Neue", Helvetica, Arial, sans-serif !default;

$base-font-size:          16px !default;
$base-font-family:        $font-family !default;
$base-line-height:        23px !default;

$headings-font-family:    inherit !default;
$headings-font-weight:    bold !default;
$headings-color:         inherit !default;


// RESPONSIVE FONT-SIZES
// --------------------------------------------------
// With mobile first - medium, large, wide, custom
/* Set variable example:
  $font-sizes: (
    null  : 28px,
    900px: 22px,
    medium: (33px, 2),
    large : 36px,
    wide: 38px
  );
*/
@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      @include breakFirst($fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}
// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}
