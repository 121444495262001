.card-deck {
  height: 100%;
  .card {
    height: 100%;
  }
  .card-heading {
    height: 125px;
    text-align: center;
  }
  .card-body {
    font-size: 14px;
    padding: 15px;
  }
}

.card-col {
  margin-bottom: 30px;
}
