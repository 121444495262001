// addtional spacing
ol { margin-left: 25px; }

// bolder font
.list-group-item-heading {
	font-weight: 700;
}
// less weight and size
.list-group {
  font-weight: 300;
	@include default-transition(all);
  &:hover, &:focus, &:active {
		@include box-shadow;
  }
}
ul.list-inline {
  margin: 15px 0;
  li {
    display: inline-block;
    margin-right: 10px;
  }
}

// Call to Action Links =======================================================================

.news-links {
	margin: 15px;
	li {
		margin: 0 0 15px;
	}
}

// show disc on list
.bullet-list {
  list-style-type: disc;
  margin-left: 30px;
}

.cta-list {
  li {
    position: relative;
    &:not(:last-child) { // don't show in last child
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        background: $gray-alt;
        width: 95%;
        margin: 0 2.5%;
      }
    }
    > a {
      display: block;
      font-size: 18px;
      padding: 10px 40px 10px 20px;
      text-decoration: none;
      &:hover, &:focus, &:active {
        background: $gray-alt;
      }
      &:before {
          position: relative;
          right: 16px;
          margin-right: -9px;
      }
      i {
        margin-right: 5px;
      }
      .fa-list-alt {
        color: $blue;
      }
    }
		span.badge {
			position: absolute;
	    top: 7px;
	    right: 5px;
		}
  }
}

.cta-links {
	margin: 15px 0;
	list-style: none;
	li {
		margin: 0 0 10px;
    > span {
      display: block;
      font-size: 16px;
      //background: $blueNav;
      border: 1px solid $blue;
      padding: 5px 10px 5px 15px;
      color: $blue;
      font-weight: 400;
    }
    a {
      display: block;
      font-size: 16px;
      padding: 10px 10px 10px 15px;
      background: $gray-alt;
      text-decoration: none;
			@include default-transition(all);
      &:hover, &:focus {
        background: $gray-alt;
  			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
      }
      &:active {
        box-shadow: inset 0px 0px 4px rgba(0,0,0,0.3);
      }
    }
    i {
      margin-right: 5px;
      color: $red;
    }
    p {
      padding: 15px 15px 0;
    }
	}
  li.active > a, .more-link {
    background: $blue;
    color: $white;
    &:before {
      color: $white;
    }
    &:hover, &:focus, &:active {
      background: $blue;
    }
  }
  // show addtion icon for more
  .more-link:after {
    font-family: fontawesome;
    content: "\f0a9";
    margin-left: 10px;
    position: relative;
  }
}
ol.cta-links {
  margin-left: 25px;
}
// darker link for call to action
.cta-links-dark {
  margin: 15px 0;
  li {
    margin-bottom: 10px;
  }
  li > a {
    display: block;
    font-size: 16px;
    padding: 10px 15px;
    background: $gray;
    text-decoration: none;
    color: $white;
    transition: all .5s ease;
    &:hover, &:focus, &:active {
      background: $gray-alt;
      color: $gray-dark;
			@include box-shadow;
    }
    &:before {
      color: $white;
    }
  }

}
.cta-links-dark-inline {
  width: 100%;
  margin: 15px 0;
  li {
    margin-bottom: 10px;
    display: block;
    @include breakFirst(medium) {
      display: table-cell;
        width: 1%;
    }
  }
  li > a {
    display: block;
    font-size: 16px;
    padding: 10px 15px;
    margin-right: 10px;
    background: $gray;
    text-decoration: none;
    color: $white;
    transition: all .5s ease;
    &:hover, &:focus, &:active {
      background: $gray-alt;
      color: $gray-dark;
			@include box-shadow;
    }
    &:before {
      color: $white;
    }
  }

}

#id_file, .form-group ul {
  list-style: none;
  margin-left: 0;
}
