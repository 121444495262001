// MEDIA QUERIES BREAKPOINTS
// --------------------------------------------------

// Extra small screen / phone
$screen-xs:                  480px !default;
$screen-phone:               $screen-xs !default;

// Small screen / tablet
$screen-sm:                  768px !default;
$screen-tablet:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  992px !default;
$screen-desktop:             $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1220px !default;
$screen-lg-desktop:          $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm - 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
$screen-md-max:              ($screen-lg - 1) !default;

// RESPONSIVE BREAKPOINTS
// --------------------------------------------------
@mixin break($media) {
  @if $media == small {
    @media (max-width: 767px) { @content; }
  }
  @else if $media == medium {
    @media (min-width: $screen-tablet) and (max-width: $screen-desktop) { @content; }
    @media (min-width: $screen-tablet) and (max-width: 1024px) and (orientation: landscape) {@content;}
  }
  @else if $media == mediumLand {
    @media (min-width: $screen-tablet) and (max-width: 1024px) and (orientation: landscape) {@content;}
  }
  @else if $media == mediumPort {
    @media (min-width: $screen-tablet) and (max-width: $screen-desktop) { @content; }
  }
  @else if $media == wide {
    @media only screen and (min-width: $screen-lg-desktop) { @content; }
  }
  @else {
    @media (min-width: $media) { @content; }
  }
}

// MOBILE FIRST RESPONSIVE BREAKPOINTS
// --------------------------------------------------
@mixin breakFirst($media) {
  @if $media == medium {
    @media (min-width: $screen-tablet) { @content; }
  }
  @else if $media == large {
    @media (min-width: $screen-desktop) { @content; }
  }
  @else if $media == wide {
    @media (min-width: $screen-lg-desktop) { @content; }
  }
  @else {
    @media (min-width: $media) { @content; }
  }
}
