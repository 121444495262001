

// Custom Margin spacing ========================================================
.m- {
	// Top
	&top10 {
		margin-top: 10px;
	}
	&top15 {
		margin-top: 15px;
	}
	&top30 {
		margin-top: 30px;
	}
	// Left
	&left5 {
		margin-left: 5px;
	}
	&left15 {
		margin-left: 15px;
	}
	&left30 {
		margin-left: 30px;
	}
	// Bottom
	&bottom0 {
		margin-bottom: 0 !important; // reset bottom margin
	}
	&bottom15 {
		margin-bottom: 15px !important;
	}
	&bottom30 {
		margin-bottom: 30px !important;
	}
	// Negative spaceing
	&left-15 {
		margin-left: -15px;
	}
	&top-30 {
		margin-top: -30px !important;
	}
	&bottom-30 {
		margin-bottom: -30px !important;
	}
}


// Custom Height sizes ========================================================
.h {
	&100 {
		height: 100px;
	}
	&200 {
		height: 200px;
	}
	&300 {
		height: 300px;
	}
	&400 {
		height: 400px;
	}
	&430 {
		height: 430px;
	}
	&500 {
		height: 500px;
	}
}


// Widths ========================================================
.w100per {
	width: 100%;
}
