.title-class {
  padding-top:10px;
  padding-bottom:10px;
  font-weight:bold;
  font-size:1.5em;
}

.heading-class {
  border-bottom: 1px solid #000000;
  padding-top:5px;
  font-weight:bold;
}

.label-class-bold { font-weight:bold; }
.label-class-20 { padding-left:20px; }
.label-class-40 { padding-left:40px; }
.data-numeric { text-align:right; }
.data-numeric-line {
  text-align: right;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}

.finstat-table {
  border-spacing: 15px;
  border-collapse: separate;
}

.header-year {
  text-align: right;
  font-weight:bold;
}
