// Transitions
// -------------------------
$cbTrans: 							cubic-bezier(0.165, 0.84, 0.44, 1);
$pageTrans: 						cubic-bezier(.54,.06,.55,.97);

// TRANSITIONS
// --------------------------------------------------
@mixin default-transition($property){
  transition: $property 0.5s $pageTrans;
}
