.social-login-container {
  ul, h4 {
    width: 100%;
    text-align: center;
  }
  ul.social-login-list {
    li {
      display: inline-block;
    }
  }
  h4 {
    display: block;
  }
}

section#contact {
  text-shadow: 1px 3px 3px #000;
}
