select.form-control {
  overflow-y: auto;
}

.form-control, .form-control:focus {
  color: $black;
}

.text-center {
  text-align: center;
  form {
    width: 100%;
  }
}