// Wharton Primary Colors

$wharton-blue: rgb(0, 71, 133);		// Wharton Blue
$wharton-red: rgb(169, 5, 51);		// Wharton Red


// Wharton Secondary Colors

$midnight-blue: rgb(38, 36, 96);	// Midnight Blue (Note: Navy Blue)
$atlantic-blue: rgb(40, 47, 133);	// Atlantic Blue (Note: Royal Blue)
$pacific-blue: rgb(2, 108, 181);	// Pacific Blue (Note: Medium Blue)
$bay-blue: rgb(6, 170, 252);		// Bay Blue (Note: Light Blue)
$evening-red: rgb(83, 42, 133);		// Evening Red (Note: Purple)
$sunset-red: rgb(150, 34, 125);		// Sunset Red (Note: Fuchsia)
$brick-red: rgb(168, 32, 78);		// Brick Red (Note: Crimson)
$heritage-red: rgb(197, 9, 59);		// Heritage Red (Note: Fire Engine Red)
$night-street: rgb(45, 44, 65);		// Night Street (Note: Charcoal)
$morning-yellow: rgb(215, 188, 106);	// Morning Yellow (Note: Mustard)
$college-gray: rgb(177, 182, 175);	// College Gray (Note: Dark Gray)
$marine-gray: rgb(238, 237, 234);	// Marine Gray (Note: Light Gray)


// Wharton Legacy Colors

$legacy-orange: rgb(243, 117, 58);	// Wharton legacy orange
$legacy-green: rgb(92, 184, 92);	// Wharton legacy green

// COLORS
// -------------------------
$white:					#ffffff !default;
$black:					#000000 !default;
$gray-dark:             $college-gray !default;
$gray-alt: 			    $marine-gray !default;
$blue: 					$wharton-blue !default;
$red: 					$heritage-red !default;

// Scaffolding
// -------------------------
$body-background:        $white !default;
$text-color:             $gray-dark !default;


// Links
// -------------------------
$link-color:             $blue !default;
$link-color-hover:       darken($link-color, 15%) !default;
