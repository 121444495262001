.main-breadcrumb {
  background-color: $gray-lighter;
  padding: 10px;
  margin-bottom: 10px;

  .breadcrumb {
    padding: .75rem 0;
    margin-left: 0;
  }
}
