footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
	padding: 40px 0;
  background-color: $brand-primary;
  color: $white;
  .row {
    margin-bottom: 30px;
  }
  li {
    margin-bottom: 5px;
  }
	span.copyright {
		text-transform: none;
		font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
		line-height: 40px;
	}
	ul.quicklinks {
		margin-bottom: 0;
		text-transform: none;
		font-family: $font_4, $font_0, $font_1, $font_2, $font_3;
		line-height: 40px;
	}
  a {
    color: $white;
    &:hover {
      color: $brand-info;
    }
  }
  .footerlogo {
    width: 245px;
  }
}
